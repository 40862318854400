$(function(){
	$('.sideNavi li').hover(function(){
		if(!$(this).hasClass('on')){
			$(this).addClass("on");
			$(this).find('.dorpBox').stop().fadeToggle();
		}
   },function(){
		if($(this).hasClass('on')){
			$(this).removeClass("on");
			$(this).find('.dorpBox').stop().fadeToggle();
		}
   });

	var sBar = false;
	var fts = true;
	if(navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0){
		sBar = true;
		fts = false;
	}
	$('#mainBox').fullpage({
		scrollingSpeed: 800,
		fitToSection: fts,
		fitToSectionDelay: 800,
		touchSensitivity: 15,
		easingcss3: "cubic-bezier(0.5, 0, 0, 1.0)",
		scrollOverflow: 1,
		verticalCentered: 0,
		scrollBar:sBar,
		onLeave: function(index, nextIndex, direction){
			if(nextIndex ==1){
				$('#gHeader h1').fadeOut();
			}
		},
		afterLoad: function(anchorLink, index){
			if(index != 1){
				$('#gHeader h1').fadeIn();
			}else{
				$('#gHeader h1').fadeOut();
			}
		}
	});

	new Swiper(".swiper-container", {
		autoplay: {
			delay: 4000,
			disableOnInteraction: 0
		},
		loop: 1,
		effect: "fade",
		slidesPerView: "auto",
		keyboard: {
			enabled: 1,
			onlyInViewport: 0
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: 1
		}
	});
});